<template>
  <b-card
    align="center"
    no-body class="overflow-hidden" style="max-width: 540px;">
  <b-row>
    <b-col md="6">
      <b-card-img :src="data_item.attributes['image-url']" alt="Image" class="rounded-0"></b-card-img>
        <b-card-body>
        <a href="#" class="card-link">View Details</a>
        <b-button block variant="primary" @click="navigateToCollectionImageUpdate">Edit</b-button>
      </b-card-body>
    </b-col>
    <b-col md="6">
      <b-card-body :title="collectionName">
        <b-card-text>
          {{subTitle}}
        </b-card-text>
      </b-card-body>
      <div v-for="(brand) in brand_groups" :key="brand.id">
        <b-card-text>
          {{brand.name}}
        </b-card-text>
        <b-list-group flush>
          <div v-for="(prod) in brand.product_items" :key="prod.id">
            <b-list-group-item>
              <b-link :to="{ name: 'ProductItemShowScreen', params: { id: prod.id } }">
              {{prod.product_listing.product_model.name}} #{{ prod.product_listing['edition']}} 
              <div v-if="prod['serial-number'] > 1"> Copy {{ prod['serial-number']}} </div>
              </b-link>
              <value-rating :product_item="prod"></value-rating>
              <five-star-rating v-model="prod['estimated-quality']"></five-star-rating>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
    </b-col>
  </b-row>
  </b-card>
</template>

<script>
import {belongsToDescription} from '@/utils'
import FiveStarRating from './FiveStarRating.vue'
import ValueRating from './ValueRating.vue'

export default {
  components: {
    FiveStarRating,
    ValueRating
  },
  props: {
    data_item: {
      Type: Object
    },
    mappings: {
      Type: Array
    },
    included: {
      Type: Array
    },
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      value: 3,
      describe_title: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ''
        }
      ],
      describe_subtitle: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'access-permission',
          append: ''
        }
      ],
      brand_groups: [

      ]
    }
  },
  computed: {
    collectionName() {
      return belongsToDescription(this.data_item, this.included, this.describe_title)

    },
    subTitle() {
      return belongsToDescription(this.data_item, this.included, this.describe_subtitle)
    }
  },
  methods: {
    callApi () {
    },
    navigateToCollectionImageUpdate() {
      let nav = {
        name: 'CollectionImageUpdateScreen', 
        params: {
          id: this.data_item.id
        }
      }
      this.$store.dispatch('query_path/follow_trail', {
        name: 'Collection Name' + ' Image',
        navigation: nav
      })
      this.$router.push(nav)
    }
  },
  mounted() {
    this.mappings.forEach( mapping_el => {
      if(mapping_el.attributes['collection-image-id'] === this.data_item.id) {
        let product_item = this.included.find(el => 
          el.type === 'product-items' && el.id == mapping_el.attributes['product-item-id']
        )
        let product_listing = this.included.find(el =>
          el.type === 'product-listings' 
          && el.id === product_item.attributes['product-listing-id']
        )
        let product_model = this.included.find(el =>
          el.type === 'product-models'
          && el.id === product_listing.attributes['product-model-id']
        )
        let brand = this.included.find(el =>
          el.type === 'brands'
          && el.id === product_model.attributes['brand-id']
        )
        let brand_group = {
          ...brand.attributes,
          product_items: []
        }
        this.brand_groups.find( 
          el =>  
          el.id === brand.id
        ) || this.brand_groups.push(brand_group)
        brand_group = this.brand_groups.find(
          el =>
          el.id === brand.id
        )
        let constructed_product_item = {
          ...product_item.attributes,
          product_listing: {
            ...product_listing.attributes,
            product_model: {
              ...product_model.attributes
            }
          }
        }
        brand_group.product_items.push(constructed_product_item )
      }
    })
  }
}
</script>

 